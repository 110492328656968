@font-face {
  font-family: "Euclid Circular A";
  src: local("Euclid Circular A"), url("../fonts/Euclid\ Circular\ A\ Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Euclid Circular A";
  src: local("Euclid Circular A"), url("../fonts/Euclid\ Circular\ A\ Light\ Italic.ttf");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Euclid Circular A";
  src: local("Euclid Circular A"), url("../fonts/Euclid\ Circular\ A\ Regular.ttf");
}
@font-face {
  font-family: "Euclid Circular A";
  src: local("Euclid Circular A"), url("../fonts/Euclid\ Circular\ A\ Italic.ttf");
  font-style: italic;
}
@font-face {
  font-family: "Euclid Circular A";
  src: local("Euclid Circular A"), url("../fonts/Euclid\ Circular\ A\ Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Euclid Circular A";
  src: local("Euclid Circular A"), url("../fonts/Euclid\ Circular\ A\ Medium\ Italic.ttf");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Euclid Circular A";
  src: local("Euclid Circular A"), url("../fonts/Euclid\ Circular\ A\ SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Euclid Circular A";
  src: local("Euclid Circular A"), url("../fonts/Euclid\ Circular\ A\ SemiBold\ Italic.ttf");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Euclid Circular A";
  src: local("Euclid Circular A Bold"), url("../fonts/Euclid\ Circular\ A\ Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Euclid Circular A";
  src: local("Euclid Circular A Bold Italic"), url("../fonts/Euclid\ Circular\ A\ Bold\ Italic.ttf");
  font-weight: 700;
  font-style: italic;
}

@tailwind base;
@layer base {
  html {
    font-family: "Euclid Circular A", ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    background-color: black;
    color: white;
  }
}
@tailwind components;
@layer components {
  .text-h1 {
    font-size: 2.5rem;
    @apply tracking-[0.125rem] leading-[3.17rem] font-bold;
  }

  .text-h2 {
    font-size: 1.875rem;
    @apply tracking-[0.0625rem] leading-[2.3375rem] font-semibold;
  }

  .text-h3 {
    font-size: 1.375rem;
    @apply tracking-[0.0625rem] leading-[1.74375rem] font-bold;
  }

  .text-t20 {
    font-size: 1.25rem;
    @apply tracking-[0.0625rem] leading-[1.585rem] font-medium;
  }

  .text-t16 {
    font-size: 1rem;
    @apply tracking-[0.0625rem] leading-[1.6rem] font-extralight;
  }

  .text-t16b {
    font-size: 1rem;
    @apply tracking-[0.0625rem] leading-[1.6rem] font-bold;
  }

  .text-t14 {
    font-size: 0.875rem;
    @apply tracking-[0.0625rem] leading-[1.4rem] font-extralight;
  }

  .text-t14b {
    font-size: 0.875rem;
    @apply tracking-[0.0625rem] leading-[1.4rem] font-bold;
  }

  .text-t12 {
    font-size: 0.75rem;
    @apply tracking-[0.0625rem] font-light;
  }

  .text-tag {
    font-size: 0.625rem;
    @apply leading-[1rem] font-bold;
  }

  .text-link {
    font-size: 0.875rem;
    @apply tracking-[0.0625rem] leading-[1rem] font-bold;
  }
}
@tailwind utilities;
@layer utilities {
  // https://stackoverflow.com/questions/61083813/how-to-avoid-internal-autofill-selected-style-to-be-applied
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s;
  }
}

/* Drawer Fade */
.drawer-fade-enter {
  opacity: 0;
}
.drawer-fade-enter > div {
  transform: translateX(100%);
}
.drawer-fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.drawer-fade-enter-active > div {
  transform: translateX(0);
  transition: transform 300ms;
}
.drawer-fade-exit {
  opacity: 1;
}
.drawer-fade-exit > div {
  transform: translateX(0);
}
.drawer-fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
.drawer-fade-exit-active > div {
  transform: translateX(100%);
  transition: transform 300ms;
}
